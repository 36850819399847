import urlJoin from 'url-join';
import { Util } from "../Util/util";

export class FetchClient {
  public token = "";
  public tenantId = "";
  public userId = "";
  public baseUri = "";
  public headers: Map<string, string> = new Map();

  public constructor(tenantId: string, userId: string) {
    const util = new Util();
    this.tenantId = tenantId;
    this.userId = userId;
  }
  public async init(tenantId: string, userId: string) {
    const util = new Util();
    this.tenantId = tenantId;
    this.userId = userId;
  }
  public addHeader(name: string, value: string) {
    this.headers.set(name, value);
  }
  public clearHeaders() {
    this.headers.clear();
  }
  public async get(url: string, headers?: { [key: string]: string }, queries: string = ""): Promise<Response> {
//    const _this = this;
//    let _headers: { [key: string]: string } = {"X-Request-Param": this.token, "X-Request-Value": this.tenantId, "X-Request-ClientAddress": "a", "X-Request-Alias": this.userId};
    const _headers: { [key: string]: string } = {};
    this.headers.forEach((value, key) => {
      _headers[key] = value;
    });
    if (headers) {
      Object.entries(headers).map((h) => _headers[h[0]] = h[1]);
    }
    const _url = urlJoin(this.baseUri, url);
    const response = await fetch(`${_url}${queries}`, { method: "GET", headers: _headers }).catch((error)=>{
      return new Response();
    });
    return response;
  }
  public async post(url: string, body: string, headers?: { [key: string]: string }, queries: string = ""): Promise<Response> {
    const _headers: { [key: string]: string } = {"X-Param": this.token, "X-Request-Value": this.tenantId, "X-Request-ClientAddress": "a", "X-Request-Alias": this.userId};
    this.headers.forEach((value, key) => {
      _headers[key] = value;
    });
    if (headers) {
      Object.entries(headers).map((h) => _headers[h[0]] = h[1]);
    }
    const _url = urlJoin(this.baseUri, url);
    const response = await fetch(`${_url}${queries}`, { method: "POST", body: body, headers: _headers });
    return response;
  }
  public async put(url: string, body: string, headers?: { [key: string]: string }, queries: string = ""): Promise<Response> {
    const _headers: { [key: string]: string } = {"X-Request-Param": this.token, "X-Request-Value": this.tenantId, "X-Request-ClientAddress": "a", "X-Request-Alias": this.userId};
    this.headers.forEach((value, key) => {
      _headers[key] = value;
    });
    if (headers) {
      Object.entries(headers).map((h) => _headers[h[0]] = h[1]);
    }
    const _url = urlJoin(this.baseUri, url);
    const response = await fetch(`${_url}${queries}`, { method: "PUT", body: body, headers: _headers });
    return response;
  }
  public async delete(url: string, headers?: { [key: string]: string }, queries: string = ""): Promise<Response> {
    const _headers: { [key: string]: string } = {"X-Request-Param": this.token, "X-Request-Value": this.tenantId, "X-Request-ClientAddress": "a", "X-Request-Alias": this.userId};
    this.headers.forEach((value, key) => {
      _headers[key] = value;
    });
    if (headers) {
      Object.entries(headers).map((h) => _headers[h[0]] = h[1]);
    }
    const _url = urlJoin(this.baseUri, url);
    const response = await fetch(`${_url}${queries}`, { method: "DELETE", headers: _headers });
    return response;
  }
}
