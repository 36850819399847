import urlJoin from "url-join";
import { Constants } from "../Util/constants";
import { LastUpdateInfo } from "../api";
import { FetchClient } from "../Util/fetch_client";
import { TokenStore } from "../factory";
import { NoNetworkError, PanelServerOfflineError, ServerOfflineError } from "../Util/exceptions";

export class ServiceBase {
  protected _token: TokenStore = new TokenStore();
  protected _userId = "";
  protected _tenantId = "";
  protected _baseUri = "";
  protected _settingBaseUri = "";
  protected client: FetchClient;
  protected settingClient: FetchClient;

  protected constructor(token: TokenStore, userId: string, tenantId: string, baseUri: string, settingBaseUri: string) {
    if (token == null) {
      throw new Error("Missing token");
    }
    if (token.isValidToken() == false) {
      throw new Error("Token has expired.");
    }
    if (!userId) {
      throw new Error("Missing User id");
    }
    if (!baseUri) {
      throw new Error("Invalid base uri");
    }
    if (!settingBaseUri) {
      throw new Error("Invalid setting base uri");
    }
    this._token = token;
    this._userId = userId;
    this._tenantId = tenantId;
    this._baseUri = baseUri;
    this._settingBaseUri = settingBaseUri;

    this.client = new FetchClient(this._tenantId, this._userId);
    this.client.baseUri = this._baseUri;
    this.settingClient = new FetchClient(this._tenantId, this._userId);
    this.settingClient.baseUri = this._settingBaseUri;
  }
  protected async get(headerParam: string, headerValue: string, endPoint: string, alias: string): Promise<Response> {
    if (navigator.onLine == false) {
      throw new NoNetworkError();
    }
    try {
      this.setHeader(this.client, headerParam, headerValue, alias);
      const response = await this.client.get(endPoint);
      return response;
    }
    catch (Exception) {
      throw new ServerOfflineError();
    }
  }

  protected async settingGet(headerParam: string, headerValue: string, endPoint: string, alias: string): Promise<Response> {
    //throw new NoNetworkError();
    if (navigator.onLine == false) {
      throw new NoNetworkError();
    }
    try {
      this.setHeader(this.settingClient, headerParam, headerValue, alias);
      const response = await this.settingClient.get(endPoint);
      return response;
    }
    catch (Exception) {
      throw new PanelServerOfflineError();
    }
  }

  protected async post(headerParam: string, headerValue: string, endPoint: string, content: string, alias: string): Promise<Response> {
    if (navigator.onLine == false) {
      throw new NoNetworkError();
    }
    try {
      this.setHeader(this.client, headerParam, headerValue, alias);
      const response = this.client.post(endPoint, content);
      return response;
    }
    catch (Exception) {
      throw new ServerOfflineError();
    }
  }

  protected async settingPost(headerParam: string, headerValue: string, endPoint: string, content: string, alias: string): Promise<Response> {
    if (navigator.onLine == false) {
      throw new NoNetworkError();
    }
    try {
      this.setHeader(this.settingClient, headerParam, headerValue, alias);
      const response = this.settingClient.post(endPoint, content);
      return response;
    }
    catch (Exception) {
      throw new PanelServerOfflineError();
    }
  }

  protected async put(headerParam: string, headerValue: string, endPoint: string, content: string, alias: string): Promise<Response> {
    if (navigator.onLine == false) {
      throw new NoNetworkError();
    }
    try {
      this.setHeader(this.client, headerParam, headerValue, alias);
      const response = await this.client.put(endPoint, content);
      return response;
    }
    catch (Exception) {
      throw new ServerOfflineError();
    }

  }

  protected async settingPut(headerParam: string, headerValue: string, endPoint: string, content: string, alias: string): Promise<Response> {
    if (navigator.onLine == false) {
      throw new NoNetworkError();
    }
    try {
      this.setHeader(this.settingClient, headerParam, headerValue, alias);
      const response = await this.settingClient.put(endPoint, content);
      return response;
    }
    catch (Exception) {
      throw new PanelServerOfflineError();
    }

  }

  protected async delete(headerParam: string, headerValue: string, endPoint: string, content: string, alias: string): Promise<Response> {
    if (navigator.onLine == false) {
      throw new NoNetworkError();
    }
    try {
      this.setHeader(this.client, headerParam, headerValue, alias);

      const response = await this.client.delete(endPoint);
      return response;
    }
    catch (Exception) {
      throw new ServerOfflineError();
    }
  }

  protected async settingDelete(headerParam: string, headerValue: string, endPoint: string, content: string, alias: string): Promise<Response> {
    if (navigator.onLine == false) {
      throw new NoNetworkError();
    }
    try {
      this.setHeader(this.settingClient, headerParam, headerValue, alias);

      const response = await this.settingClient.delete(endPoint);
      return response;
    }
    catch (Exception) {
      throw new PanelServerOfflineError();
    }
  }

  private setHeader(client: FetchClient, headerParam: string, headerValue: string, userAlias: string) {
    if (client) {
      client.clearHeaders();
      client.addHeader("Content-Type", "application/json");
      client.addHeader(Constants.HEADER_REQUEST_PARAM, headerParam);
      client.addHeader(Constants.HEADER_REQUEST_VALUE, headerValue);
      client.addHeader(Constants.HEADER_REQUEST_ADDRESS, this.getRemoteAddress());
      if (userAlias) {
        client.addHeader(Constants.HEADER_REQUEST_ALIAS, userAlias);
      }
    }
  }
  private getRemoteAddress() {
    return "";
  }
}

export class FetchInfo {
  _headers: { [key: string]: string } = {};
  _baseUri = "";

  public combineUrl(fragment: string) {
    return urlJoin(this._baseUri, fragment);
  }
  public set url(value: string) {
    this._baseUri = value;
  }
  public clearHeaders() {
    this._headers = {};
  }
  public addHeader(headerName: string, headerValue: string) {
    this._headers[headerName] = headerValue;
  }
  public get headers() {
    return this._headers;
  }
}
