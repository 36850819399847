<template>
  <div class="home">
    <div v-for="panel in context.panels" v-bind:key="panel.Id">
      {{ panel.Name }}
    </div>
    <div>{{context.message}} {{ context.panels.length }}</div>
</div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import { Context } from '@/appModel/context';

export default defineComponent({
  name: 'HomeView',
  data() {
    return {
      context: inject('context') as Context,
    };
  },
});
</script>
