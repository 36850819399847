import { AppError } from "@/crpconnect/crp.service";

export class Util {
  public static ensureSuccess(response: Response, message: string) {
    if (response.ok) {
      return response
    }
    throw new AppError(message);
  }
  public static dateReplacer(key: string, value: any) {
    if (typeof (value) === "string" && (value as string).match(/^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(\.\d+)?)?/)) {
      const dt = new Date(value);
      const tz = dt.getTimezoneOffset();
      const tzh = Math.floor(Math.abs(tz) / 60);
      const tzm = Math.abs(tz) - tzh * 60;
      const tzstr = `${tz < 0 ? "-" : "+"}${("0" + tzh).slice(-2)}:${("0" + tzm).slice(-2)}`;
      return `${dt.format("yyyy-MM-ddThh:mm:ss.u")}${tzstr}`;
    }
    else {
      return value;
    }
  }
  public static dateReviver(key: string, value: any) {
    if (typeof (value) === "string" && (value as string).match(/^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(\.\d+)?([+-]\d{2}(:\d{2})?)?)?/)) {
      const dstr = value;
      const dobj = new Date(value);
      return new Date(value);
    }
    else {
      return value;
    }
  }
  public static base642base64url(base64: string) {
    return base64.replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");
  }
  public static base642ArrayBuffer(str: string): ArrayBuffer {
    const binary = atob(str);
    const buf = new ArrayBuffer(binary.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0; i < binary.length; i++) {
      bufView[i] = binary.charCodeAt(i);
    }
    return buf;
  }
  public static string2ArrayBuffer(str: string): ArrayBuffer {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0; i < str.length; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }
  public static arrayBuffer2base64(buffer: ArrayBuffer) {
    const bytes = new Uint8Array(buffer);
    let b: string = "";
    for (let i = 0; i < bytes.length; i++) {
      b += String.fromCharCode(bytes[i]);
    }
    return btoa(b);
  }
}

declare global {
  export interface Date {
    addDay(d: number): Date;
    firstWeek(): Date;
    isThisMonth(): boolean;
    isSaturday(): boolean;
    isSunday(): boolean;
    toSimpleString(): string;
    toSimpleDateString(): string;
    toSimpleTimeString(): string;
    toSQLString(): string;
    toOffsetString(): string;
    toUTCOffsetString(): string;
    format(format: string): string;
    utcFormat(format: string): string;
    getDayString(): string;
    startOfDay(): Date;
    startOfDayTime(): number;
    getDayTick(): number;
  }
  export interface Number {
    format(d: number): string;
  }
}

Date.prototype.addDay = function (d: number): Date {
  return new Date(this.getTime() + d * 86400000);
}

Date.prototype.firstWeek = function (): Date {
  const first = this.addDay(-this.getDate() + 1);
  return first.addDay(-first.getDay());
}

Date.prototype.isSaturday = function (): boolean {
  return (this.getDay() == 6);
}

Date.prototype.isSunday = function (): boolean {
  return (this.getDay() == 0);
}

Date.prototype.format = function (format: string) {
  const elements: { [key: string]: (date: Date) => string } = {
    hh: function (date: Date) { return ('0' + date.getHours()).slice(-2); },
    h: function (date: Date) { return date.getHours().toString(); },
    mm: function (date: Date) { return ('0' + date.getMinutes()).slice(-2); },
    m: function (date: Date) { return date.getMinutes().toString(); },
    ss: function (date: Date) { return ('0' + date.getSeconds()).slice(-2); },
    dd: function (date: Date) { return ('0' + date.getDate()).slice(-2); },
    u: function (date: Date) { return ('00' + date.getMilliseconds()).slice(-3); },
    d: function (date: Date) { return date.getDate().toString(); },
    s: function (date: Date) { return date.getSeconds().toString(); },
    yyyy: function (date: Date) { return date.getFullYear().toString(); },
    yy: function (date: Date) { return date.getFullYear().toString().slice(-2); },
    t: function (date: Date) { return date.getDate() <= 3 ? ["st", "nd", "rd"][date.getDate() - 1] : 'th'; },
    w: function (date: Date) { return ["Sun", "$on", "Tue", "Wed", "Thu", "Fri", "Sat"][date.getDay()]; },
    MMMM: function (date: Date) { return ["January", "February", "$arch", "April", "$ay", "June", "July", "August", "September", "October", "November", "December"][date.getMonth()]; },
    MMM: function (date: Date) { return ["Jan", "Feb", "$ar", "Apr", "$ay", "Jun", "Jly", "Aug", "Spt", "Oct", "Nov", "Dec"][date.getMonth()]; },
    MM: function (date: Date) { return ('0' + (date.getMonth() + 1).toString()).slice(-2); },
    M: function (date: Date) { return (date.getMonth() + 1).toString(); },
    $: function (date: Date) { return 'M'; }
  };
  const priority: string[] = ["hh", "h", "mm", "m", "ss", "dd", "d", "s", "yyyy", "yy", "t", "w", "MMMM", "MMM", "MM", "M", "$", "u"];
  return priority.reduce((res, p) => res.replace(p, elements[p](this)), format)
}

Date.prototype.utcFormat = function (format: string) {
  const elements: { [key: string]: (date: Date) => string } = {
    hh: function (date) { return ('0' + date.getUTCHours()).slice(-2); },
    h: function (date) { return date.getUTCHours().toString(); },
    mm: function (date) { return ('0' + date.getUTCMinutes()).slice(-2); },
    m: function (date) { return date.getUTCMinutes().toString(); },
    ss: function (date) { return ('0' + date.getUTCSeconds()).slice(-2); },
    dd: function (date) { return ('0' + date.getUTCDate()).slice(-2); },
    u: function (date) { return ('00' + date.getUTCMilliseconds()).slice(-3); },
    d: function (date) { return date.getUTCDate().toString(); },
    s: function (date) { return date.getUTCSeconds().toString(); },
    yyyy: function (date) { return date.getUTCFullYear() + ''; },
    yy: function (date) { return date.getUTCFullYear().toString().slice(-2); },
    t: function (date) { return date.getUTCDate() <= 3 ? ["st", "nd", "rd"][date.getUTCDate() - 1] : 'th'; },
    w: function (date) { return ["Sun", "$on", "Tue", "Wed", "Thu", "Fri", "Sat"][date.getUTCDay()]; },
    MMMM: function (date) { return ["January", "February", "$arch", "April", "$ay", "June", "July", "August", "September", "October", "November", "December"][date.getUTCMonth()]; },
    MMM: function (date) { return ["Jan", "Feb", "$ar", "Apr", "$ay", "Jun", "Jly", "Aug", "Spt", "Oct", "Nov", "Dec"][date.getUTCMonth()]; },
    MM: function (date) { return ('0' + (date.getUTCMonth() + 1)).slice(-2); },
    M: function (date) { return (date.getUTCMonth() + 1).toString(); },
    $: function (date) { return 'M'; }
  };
  const priority: string[] = ["hh", "h", "mm", "m", "ss", "dd", "d", "s", "yyyy", "yy", "t", "w", "MMMM", "MMM", "MM", "M", "$", "u"];
  return priority.reduce((res, p) => res.replace(p, elements[p](this)), format)
}

Date.prototype.toSimpleDateString = function () {
  return this.format("yyyy/MM/dd");
}

Date.prototype.toSimpleTimeString = function () {
  return this.format("hh:mm");
}

Date.prototype.toSimpleString = function () {
  return this.format("yyyy/MM/dd hh:mm");
}

Date.prototype.toSQLString = function () {
  return this.format("yyyy-MM-ddThh:mm:ss.u");
}

Date.prototype.toOffsetString = function () {
  const offset = this.getTimezoneOffset()
  return this.format("yyyy-MM-ddThh:mm:ss") + (offset < 0 ? "+" : "-") + ("0" + Math.floor(Math.abs(offset) / 60).toString()).slice(-2) + ":" + ("0" + (Math.abs(offset) % 60).toString()).slice(-2);
}

Date.prototype.toUTCOffsetString = function () {
  return this.utcFormat("yyyy-MM-ddThh:mm:ss") + "+00:00";
}


Date.prototype.getDayString = function () {
  return ['日', '月', '火', '水', '木', '金', '土'][this.getDay()];
}

Date.prototype.startOfDay = function () {
  const offset = this.getTimezoneOffset() * 60000;

  return new Date(Math.floor((this.getTime() - offset) / (24 * 3600000)) * 24 * 3600000 + offset);
}

Date.prototype.startOfDayTime = function () {
  const offset = this.getTimezoneOffset() * 60000;

  return Math.floor((this.getTime() - offset) / (24 * 3600000)) * 24 * 3600000 + offset;
}

Date.prototype.getDayTick = function () {
  const offset = this.getTimezoneOffset() * 60000;

  return Math.floor((this.getTime() - offset) / (24 * 3600000));
}
