/// <summary>
/// テナント情報管理

import { AppError } from "@/crpconnect/crp.service";
import { EndPoints } from "../api";
import { TokenStore } from "../factory";
import { ServiceBase } from "../ServiceBase/service_base"
import { Util } from "../Util/util";

/// </summary>
export class TenantService extends ServiceBase {
    /// <summary>
    /// コンストラクタ
    /// Factoryによって呼び出される
    /// </summary>
    /// <param name="token">認証Token</param>
    /// <param name="userId">操作ユーザーID</param>
    /// <param name="tenantId">TenantId</param>
    /// <param name="baseUri">end point base uri</param>
    constructor(token: TokenStore, userId: string, tenantId: string, baseUri: string, settingBaseUri: string) {
        super(token, userId, tenantId, baseUri, settingBaseUri);
    }
    /// <summary>
    /// テナント情報の最終更新日時を取得する
    /// </summary>
    /// <returns></returns>
    public async getLastUpdate(): Promise<Date> {
        const response: Response = await this.get(
            this._token.token,
            this._tenantId,
            `${EndPoints.VERSION_PREFIX}${EndPoints.TENANT_LASTUPDATE}`,
            this._userId
        );
        Util.ensureSuccess(response, "最終更新情報の取得に失敗しました");
        try {
            const result = new Date(await response.text());
            return result;
        }
        catch (error) {
            throw new Error("最終更新情報の処理が失敗しました");
        }
    }
    /// <summary>
    /// テナントのロゴ情報を取得する
    /// </summary>
    /// <returns></returns>
    public async getLogo(): Promise<ArrayBuffer> {
        const response: Response = await this.get(
            this._token.token,
            this._tenantId,
            `${EndPoints.VERSION_PREFIX}${EndPoints.TENANT}`,
            this._userId
        );
        Util.ensureSuccess(response, "ロゴの取得に失敗しました");
        try {
            const tenant = JSON.parse(await response.text(), Util.dateReviver);
            return tenant.CompanyLogo;
        }
        catch (error) {
            throw new Error("ロゴの処理が失敗しました");
        }
    }
    /// <summary>
    /// テナント付加情報を取得する
    /// </summary>
    /// <returns></returns>
    public async getTenant(): Promise<string> {
        const response = await this.get(
            this._token.token,
            this._tenantId,
            `${EndPoints.VERSION_PREFIX}${EndPoints.TENANT_ADDITIONAL_INFO}`,
            this._userId
        );


        //エラー発生時点でキャッシュは無効とする
        Util.ensureSuccess(response, "テナント情報の取得に失敗しました");

        try {
            const result = await response.text();
            return result;
        }
        catch (error) {
            throw new Error("テナント情報の処理が失敗しました");
        }
    }
    /// <summary>
    /// テナント付加情報の更新
    /// </summary>
    /// <param name="additionalInfo">更新するAdditionalInfo</param>
    /// <returns></returns>
    /// <exception cref="ArgumentNullException">Missing parameter</exception>
    /// <exception cref="JsonException">引数がJsonでは無かった</exception>
    public async update(additionalInfo: string) {
        //前提条件チェック
        if (!additionalInfo || additionalInfo.match(/^ *$/)) {
            throw new AppError("Invalid additinalInfo");
        }
        try {
            JSON.parse(additionalInfo);
        }
        catch (err) {
            //Jsonでは無かった
            throw new AppError('Not a JSON' /*, {cause: err}*/);
        }

        let content = "";
        try {
            const additionalInfoToSend: TenantAdditionalInfo = new TenantAdditionalInfo();
            additionalInfoToSend.info = additionalInfo;

            content = JSON.stringify(additionalInfoToSend, Util.dateReplacer)
        }
        catch (err) {
            throw new AppError("テナント情報の更新に失敗しました");
        }

        const response: Response = await this.put(
            this._token.token,
            this._tenantId,
            `${EndPoints.VERSION_PREFIX}${EndPoints.TENANT_ADDITIONAL_INFO}`,
            content,
            this._userId
        );
        Util.ensureSuccess(response, "テナント情報の更新に失敗しました");
    }
}

class TenantAdditionalInfo {
    public info: string = "";
}

