<template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
  <div>{{ a }}</div>
  <router-view/>
</template>


<script setup lang="ts">
  import { onMounted, onUpdated, provide } from "vue";
  import { CRPConnectionInfo } from '@/crpconnect/crp.service';
  import { Context } from '@/appModel/context';
  import { useRoute } from 'vue-router';

  const context: Context = Context.getContext();
  provide('context', context);
  const route = useRoute();
  await CRPConnectionInfo.init();
  let ep = ""
  if (route.query.ep) {
    let ep = route.query.ep;
  }
  alert(ep);
  CRPConnectionInfo.EndPoint = ep;
  await context.init();
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
