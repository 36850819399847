import { ServiceBase } from "../ServiceBase/service_base"
import { Room } from "../api";
import { EndPoints } from "../api";
import { Util } from "../Util/util";
import { TokenStore } from "../factory";
import { AppError } from "@/crpconnect/crp.service";

/// <summary>
/// 会議室管理クラス
/// </summary>
export class RoomService extends ServiceBase {
  /// <summary>
  /// コンストラクタ
  /// Factoryによって呼び出される
  /// </summary>
  /// <param name="token">認証Token</param>
  /// <param name="userId">操作ユーザーID</param>
  /// <param name="tenantId">TenantId</param>
  /// <param name="baseUri">end point base uri</param>

  constructor(token: TokenStore, userId: string, tenantId: string, baseUri: string, settingBaseUri: string) {
    super(token, userId, tenantId, baseUri, settingBaseUri)
  }

  /// <summary>
  /// 登録されている会議室の一覧を取得する
  /// </summary>
  /// <returns></returns>
  public async getList(): Promise<Room[]> {
      const response: Response = await this.get(
        this._token.token,
        this._tenantId,
        `${EndPoints.VERSION_PREFIX}${EndPoints.ROOM}`,
        this._userId
      );


      //エラー発生時点でキャッシュは無効とする
      Util.ensureSuccess(response, "会議室の取得に失敗しました");
      try {
        const text = await response.text();
        const result = JSON.parse(text, Util.dateReviver) as Room[];
        return result.filter(x => (!x.RoomAvailable || x.RoomAvailable == true));
      }
      catch (error) {
        throw new AppError("会議室の処理が失敗しました");
      }
  }

  /// <summary>
  /// 指定された会議室の会議室画像を取得する
  /// </summary>
  /// <param name="roomId">会議室ID</param>
  /// <returns>会議室画像</returns>
  /// <exception cref="ArgumentException">roomIdが正でない</exception>
  public async getImage(roomId: number): Promise<ArrayBuffer> {
      if (roomId < 0) { throw new AppError("roomId is required"); }

      const response: Response = await this.get(
        this._token.token,
        this._tenantId,
        `${EndPoints.VERSION_PREFIX}${EndPoints.ROOM_IMAGE}?roomId=${roomId}`,
        this._userId
      );


      //エラー発生時点でキャッシュは無効とする
      Util.ensureSuccess(response, "イメージの取得に失敗しました");
      try {
        const resultStr = await response.text();

        const result: ArrayBuffer = Util.base642ArrayBuffer(resultStr);
        return result;
      }
      catch (error) {
        if (!(error instanceof Error)) {
          throw error;
        }
        else {
          throw new AppError("イメージの処理が失敗しました");
        }
      }
  }

  /// <summary>
  /// 最終更新情報の取得
  /// </summary>
  /// <returns></returns>
  public async getLastUpdate(): Promise<Date> {
      const response: Response = await this.get(
        this._token.token,
        this._tenantId,
        `${EndPoints.VERSION_PREFIX}${EndPoints.ROOM}${EndPoints.LAST_UPDATE}`,
        this._userId
      );
      //エラー発生時点でキャッシュは無効とする
      Util.ensureSuccess(response, "最終更新情報の取得に失敗しました");
      try {
        const result = new Date(await response.text());
        return result;
      }
      catch (error) {
        throw new AppError("最終更新情報の処理が失敗しました");
      }
  }
}
