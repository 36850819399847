import { ServiceBase } from "../ServiceBase/service_base";
import { ReservationStatus } from "../Util/constants";
import { EndPoints, MeetingListForAdmin, Reservation } from "../api";
import { Util } from "../Util/util";
import { TokenStore } from "../factory";
import { AppError } from "@/crpconnect/crp.service";

export class ReservationService extends ServiceBase {
    constructor(token: TokenStore , userId: string, tenantId: string, baseUri: string, settingBaseUri: string){
        super(token, userId, tenantId, baseUri, settingBaseUri);
    }
    public async getReservationList(from: Date, to: Date, lastUpdate?: Date, siteId = 0, floorId = 0, upperLimitStatus: ReservationStatus = ReservationStatus.DrinkServed, roomId = 0, panelName = "") : Promise<MeetingListForAdmin[]>
    {
            const lastUpdateQuery: string = lastUpdate
                                        ? `&lastUpdate=${encodeURIComponent(lastUpdate.toISOString())}`
                                        : `&lastUpdate=2018-01-01`;
            const siteIdQuery: string = 0 < siteId
                                        ? `&siteId=${siteId.toString()}`
                                        : "";
            const floorIdQuery: string = 0 < floorId
                                        ? `&floorId=${floorId.toString()}`
                                        : "";

            const roomIdQuery: string = 0 < roomId
                                        ? `roomIds=${roomId.toString()}`
                                        : "";

            const panelNameQuery: string = !panelName
                                        ? `&panelName=Unknown`
                                        : `&panelName=${panelName}`;

            const response: Response = await this.get(
                                    this._token.token,
                                    this._tenantId,
//                                    `${EndPoints.VERSION_PREFIX}${EndPoints.ADMIN_MEETING_LIST}?from=${encodeURIComponent(from.toUTCOffsetString())}&to=${encodeURIComponent(to.toUTCOffsetString())}${lastUpdateQuery}${siteIdQuery}${floorIdQuery}${roomIdQuery}${panelNameQuery}`,
                                    `${EndPoints.VERSION_PREFIX}${EndPoints.ADMIN_MEETING_LIST}?${roomIdQuery}${panelNameQuery}`,
                                    this._userId
                                    );


            //エラー発生時点でキャッシュは無効とする
            Util.ensureSuccess(response, "予約情報の一覧の取得に失敗しました");
            try
            {
                const text = await response.text();
                // let result: MeetingListForAdmin[] = [];
                const result: MeetingListForAdmin[] = JSON.parse(text, Util.dateReviver).filter((x: MeetingListForAdmin)=>{
                    return (x.ReservationStatusId !==undefined && x.ReservationStatusId !== null && x.ReservationStatusId <= upperLimitStatus)});
                return result;
            }
            catch (error)
            {
                throw new AppError("予約情報一覧の処理が失敗しました");
            }
    }
    // 単一の予約を取得する
    public async getReservation(meetingId: number): Promise<Reservation>
    {
        if (meetingId < 0)
        {
            throw new AppError("Invalid meetingId");
        }

        const response: Response = await this.get(
                                this._token.token,
                                this._tenantId,
                                `${EndPoints.VERSION_PREFIX}${EndPoints.RESERVATION_LIST}/${meetingId.toString()}`,
                                this._userId
                                );

        //エラー発生時点でキャッシュは無効とする
        Util.ensureSuccess(response, "予約情報の取得に失敗しました");
        try
        {
            const result: Reservation = JSON.parse(await response.text(), Util.dateReviver);
            return result;
        }
        catch (error)
        {
            throw new AppError("予約情報の処理が失敗しました");
        }
    }
    public async getLastUpdate(): Promise<Date>
    {
        const response = await this.get(
                                this._token.token,
                                this._tenantId,
                                `${EndPoints.VERSION_PREFIX}${EndPoints.GET_LASTUPDATE}`,
                                this._userId
                                );

        //エラー発生時点でキャッシュは無効とする
        Util.ensureSuccess(response, "最終更新情報の取得に失敗しました");
        try
        {
            const result = new Date(await response.text());
            return new Date();
        }
        catch (error)
        {
            throw new AppError("最終更新情報の処理が失敗しました");
        }
    }
    public async extend(meetingId: number, n: number)
    {
        //前提条件チェック
        //MeetingIDが有効であるか
        if (meetingId <= 0)
        {
            throw new AppError("Invalid Meeting Id");
        }

        let content = "";
        try
        {
            content = JSON.stringify({ MeetingId: meetingId, ExtentMinutes: n }, Util.dateReplacer)
        }
        catch (error)
        {
            throw new AppError("予約の延長に失敗しました");
        }

        const response = await this.post(
                                this._token.token,
                                this._tenantId,
                                `${EndPoints.VERSION_PREFIX}${EndPoints.EXTEND_MEETING}`,
                                content,
                                this._userId
                                );

        Util.ensureSuccess(response, "予約の延長に失敗しました");
    }
    public async checkIn(meetingId: number)
    {
        //前提条件チェック
        //MeetingIDが有効であるか
        if (meetingId <= 0)
        {
            throw new AppError("Invalid Meeting Id");
        }

        let content = "";
        try
        {
            content = JSON.stringify({ MeetingId: meetingId, StatusToUpdate: ReservationStatus.RoomEntered }, Util.dateReplacer)
        }
        catch (Exception)
        {
            throw new AppError("チェックインに失敗しました");
        }

        const response = await this.post(
                                this._token.token,
                                this._tenantId,
                                `${EndPoints.VERSION_PREFIX}${EndPoints.CHECKIN_TO_MEETING}`,
                                content,
                                this._userId
                                );

        Util.ensureSuccess(response, "チェックインに失敗しました");
    }
    public async checkOut(meetingId: number)
    {
        //前提条件チェック
        //MeetingIDが有効であるか
        if (meetingId <= 0) {
            throw new AppError("Invalid Meeting Id");
        }

        let content = "";

        try
        {
          content = JSON.stringify({ MeetingId: meetingId }, Util.dateReplacer)
        }
        catch (error)
        {
            throw new AppError("チェックアウトに失敗しました");
        }

        const response = await this.post(
                                this._token.token,
                                this._tenantId,
                                `${EndPoints.VERSION_PREFIX}${EndPoints.CHECKOUT_FROM_MEETING}`,
                                content,
                                this._userId
                                );

        Util.ensureSuccess(response, "チェックアウトに失敗しました");
    }
}
