// 予約ステータス
export enum ReservationStatus {
  // 未発券
  UnIssued = 0,
  // 発券済
  Issued = 1,
  // 入館済
  Entered = 2,
  // 入室済
  RoomEntered = 3,
  // 給茶済
  DrinkServed = 4,
  // 退室済
  RoomFinished = 5,
  // 清掃済
  CleanedUp = 6,
  // 退館済み
  AllVisitorsLeft = 7
}

// 来訪者ステータス
export enum VisitorStatus {
  // 未発券
  UnIssued = 0,
  // 発券済
  Issued = 1,
  // 入館済
  Entered = 2,
  // 退館済み
  Left = 3
}

// 定数クラス
export class Constants {
  static readonly PUBLIC_KEY = "<RSAKeyValue><Modulus>ycdJm2LSHHYSRN+BNSoDRBxTKr+xmRzaL9Xdadmy6IKmkva2NbCoxzfI+Drrgfv4/kEwsTdDIqXWp6BaViJjK83y9f0pUDtobxbejKlugvmB9hH8Fssad5BFZgu5FHQD4OKDPQfjEvVTBDA6KVX9CkSuzBIymZwpnT3tILzgVt0=</Modulus><Exponent>AQAB</Exponent></RSAKeyValue>";

  // HTTP-HEADER Key - Request Param
  static readonly HEADER_REQUEST_PARAM = "X-Request-Param";

  // HTTP-HEADER Key - Request Value
  static readonly HEADER_REQUEST_VALUE = "X-Request-Value";

  // 初期テナント情報取得用GUID
  static readonly INITIAL_TENANT_ID = "E8D86104-06BA-463D-9EF5-882E98B3CFC1";

  // HTTP-Header - リモートアドレス
  static readonly HEADER_REQUEST_ADDRESS = "X-Request-ClientAddress";

  // HTTP=Header - ユーザーAlias
  static readonly HEADER_REQUEST_ALIAS = "X-Request-Alias";

  // To check N/A Date from GuestCool
  static readonly GUESTCOOL_MIN_DATE = -62135630000000;
}
