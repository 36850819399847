/// <summary>
/// すべての入り口となるクラス
/// Singleton Pattern を適用する

import { RoomService } from "./Facilities/room_service";
import { SiteService } from "./Facilities/site_service";
import { ReservationService } from "./Reservations/reservation_service";
import { TenantService } from "./Tenant/tenant";
import { FetchClient } from "./Util/fetch_client";
import { Util } from "./Util/util";
import { Constants } from "./Util/constants";
import { SettingService } from "./Settings/setting_service";

/// </summary>
export class Factory {
  /// <summary>
  /// 動作するテナントID
  /// </summary>
  private _tenantId: string = "";

  /// <summary>
  /// サービスエンドポイント
  /// </summary>
  private _endPoint: string = "";

  /// <summary>
  /// サービスエンドポイント
  /// </summary>
  private _settingEndPoint: string = "";

  /// <summary>
  /// 操作ユーザーId
  /// </summary>
  private _userId: string = "";

  /// <summary>
  /// インスタンス
  /// </summary>
  private static _instance: Factory | null;

  /// <summary>
  /// 発行されたToken
  /// </summary>
  private _tokenStore: TokenStore | null = null;

  /// <summary>
  /// Tokenを取得済みであるか？
  /// </summary>
  public get hasToken() { return this._tokenStore != null }
  /// <summary>
  /// Tokenが有効か？
  /// </summary>
  public get isValidToken() { return (this.hasToken && this._tokenStore?.isValidToken) }

  /// <summary>
  /// Factory コンストラクタ
  /// </summary>
  /// <param name="tenantId">動作するテナントId</param>
  /// <param name="endPoint">サービスエンドポイント</param>
  /// <param name="userId">操作ユーザーID</param>
  constructor(tenantId: string, endPoint: string, settingEndPoint: string, userId: string) {
    this._tenantId = tenantId;
    this._endPoint = endPoint;
    this._settingEndPoint = settingEndPoint;
    this._userId = userId;
  }

  /// <summary>
  /// インスタンス取得
  /// </summary>
  /// <param name="tenantId">TenantId</param>
  /// <param name="endPoint">サービスエンドポイント</param>
  /// <param name="userId">操作ユーザーID</param>
  /// <returns></returns>
  public static async getInstance(tenantId: string, endPoint: string, settingEndPoint: string, userId: string): Promise<Factory> {
    if (Factory._instance == null || Factory._instance._tenantId != tenantId) {
      Factory._instance = new Factory(tenantId, endPoint, settingEndPoint, userId);
    }
    if (Factory._instance.hasToken == false || Factory._instance.isValidToken == false) {
      await Factory._instance.getToken();
    }
    await Factory._instance.tenant.getLastUpdate();

    return Factory._instance;
  }

  public RevokeToken() {
    this._tokenStore = null;
    this._room = null;
    this._reservation = null;
    this._tenant = null;
  }

  /// <summary>
  /// Tokenを評価して再取得する
  /// </summary>
  private static checkAndRegetToken() {
    if (Factory._instance?.hasToken == false || Factory._instance?.isValidToken == false) {
      //Tokenがない または 失効しているならば、Tokenを取り直す
      Factory._instance.getToken();
    }
  }

  /// <summary>
  /// 一次認証トークンの取得
  /// </summary>
  /// <returns>一次認証トークン</returns>
  private async getToken(): Promise<string> {
    //時刻をそろえるために取得しておく
    const sendingNow: Date = new Date();

    const requestKey: string = `${sendingNow.utcFormat("yyyy-MM-dd-hh-mm-ss")}-${this._tenantId}`;
    const requestValue: string = `${sendingNow.utcFormat("hh-mm-ss")}-${this._tenantId}`;
      const keyString = "ycdJm2LSHHYSRN+BNSoDRBxTKr+xmRzaL9Xdadmy6IKmkva2NbCoxzfI+Drrgfv4/kEwsTdDIqXWp6BaViJjK83y9f0pUDtobxbejKlugvmB9hH8Fssad5BFZgu5FHQD4OKDPQfjEvVTBDA6KVX9CkSuzBIymZwpnT3tILzgVt0=";


      const key = await window.crypto.subtle.importKey("jwk", { alg: "RSA-OAEP", kty: "RSA", use: "enc", n: Util.base642base64url(keyString), e: "AQAB" }, { name: "RSA-OAEP", hash: "SHA-1" }, false, ["encrypt"]);


      const result = await window.crypto.subtle.encrypt({ name: "RSA-OAEP" }, key, Util.string2ArrayBuffer(requestKey));
      const param = Util.arrayBuffer2base64(result);

      const client = new FetchClient(this._tenantId, this._userId);
      client.baseUri = this._endPoint;
      client.clearHeaders();
      client.addHeader(Constants.HEADER_REQUEST_PARAM, param);
      client.addHeader(Constants.HEADER_REQUEST_VALUE, requestValue);

      const firstKeyResponse = await client.get("/v1/auth/issueRequestKey");

      if (!Factory.IsSuccess(firstKeyResponse)) {
        throw new Error(firstKeyResponse.status.toString());
      }
      const firstKey = await firstKeyResponse.text();
      client.clearHeaders();
      client.addHeader(Constants.HEADER_REQUEST_PARAM, firstKey);
      client.addHeader(Constants.HEADER_REQUEST_VALUE, this._tenantId);

      const tokenResponse = await client.get("/v1/auth/issueToken");
      const a = await tokenResponse.text();
      const token = JSON.parse(a, Util.dateReviver);

      this._tokenStore = new TokenStore(token);
      return token;
  }
  public static IsSuccess(response: Response) {
    return (Math.floor(response.status / 100) == 2);
  }

  private _site: SiteService | null = null;
  /// <summary>
  /// サイト情報クラス
  /// </summary>
  /// <exception cref="ArgumentNullException">Tokenが取得されていない</exception>
  /// <exception cref="ArgumentOutOfRangeException">Tokenが失効している</exception>
  /// <exception cref="ArgumentException">UserIDが設定されていない</exception>
  public get site(): SiteService //=> new SiteService(_tokenStore, _userId, _tenantId, _endPoint);
  {
    Factory.checkAndRegetToken();
    if (this._site == null) {
      this._site = new SiteService(this._tokenStore as TokenStore, this._userId, this._tenantId, this._endPoint, this._settingEndPoint);
    }
    return this._site;
  }


  private _room: RoomService | null = null;
  /// <summary>
  /// 会議室情報クラス
  /// </summary>
  /// <exception cref="ArgumentNullException">Tokenが取得されていない</exception>
  /// <exception cref="ArgumentOutOfRangeException">Tokenが失効している</exception>
  /// <exception cref="ArgumentException">UserIDが設定されていない</exception>
  public get room(): RoomService //=> new RoomService(_tokenStore, _userId, _tenantId, _endPoint);
  {
    Factory.checkAndRegetToken();
    if (this._room == null) {
      this._room = new RoomService(this._tokenStore as TokenStore, this._userId, this._tenantId, this._endPoint, this._settingEndPoint);
    }
    return this._room;
  }



  private _reservation: ReservationService | null = null;
  /// <summary>
  /// 予約情報クラス
  /// </summary>
  /// <exception cref="ArgumentNullException">Tokenが取得されていない</exception>
  /// <exception cref="ArgumentOutOfRangeException">Tokenが失効している</exception>
  /// <exception cref="ArgumentException">UserIDが設定されていない</exception>
  public get reservation(): ReservationService //=> new ReservationService(_tokenStore, _userId, _tenantId, _endPoint);
  {
    Factory.checkAndRegetToken();
    if (this._reservation == null) {
      this._reservation = new ReservationService(this._tokenStore as TokenStore, this._userId, this._tenantId, this._endPoint, this._settingEndPoint);
    }
    return this._reservation;
  }

  private _tenant: TenantService | null = null;
  /// <summary>
  /// テナント情報クラス
  /// </summary>
  /// <exception cref="ArgumentNullException">Tokenが取得されていない</exception>
  /// <exception cref="ArgumentOutOfRangeException">Tokenが失効している</exception>
  /// <exception cref="ArgumentException">UserIDが設定されていない</exception>
  public get tenant(): TenantService {
    if (this._tenant == null) {
      this._tenant = new TenantService(this._tokenStore as TokenStore, this._userId, this._tenantId, this._endPoint, this._settingEndPoint);
    }
    return this._tenant;
  }

  private _setting: SettingService | null = null;
  /// <summary>
  /// テナント情報クラス
  /// </summary>
  /// <exception cref="ArgumentNullException">Tokenが取得されていない</exception>
  /// <exception cref="ArgumentOutOfRangeException">Tokenが失効している</exception>
  /// <exception cref="ArgumentException">UserIDが設定されていない</exception>
  public get setting(): SettingService {
    if (this._setting == null) {
      this._setting = new SettingService(this._tokenStore as TokenStore, this._userId, this._tenantId, this._endPoint, this._settingEndPoint);
    }
    return this._setting;
  }
}
/// <summary>
/// 返却されたTokenの内容を保持するクラス
/// </summary>
export class TokenResponse {
  /// <summary>
  /// Token文字列
  /// </summary>
  public Token: string = "";
  /// <summary>
  /// 有効期限
  /// </summary>
  public ValidUntil: number = 0;
}

export class TokenStore {
  /// <summary>
  /// Token文字列
  /// </summary>
  public token: string = "";
  /// <summary>
  /// 有効期限
  /// </summary>
  public validUntil: number = 0;
  /// <summary>
  /// Token発行日時
  /// </summary>
  public readonly tokenIssueTime: Date = new Date();
  /// <summary>
  /// Tokenが有効であるか？
  /// </summary>
  public isValidToken: () => boolean = () => { return Date.now() <= this.tokenIssueTime.getTime() + this.validUntil * 900 };

  public constructor(response?: TokenResponse) {
    if (response) {
      this.token = response.Token;
      this.validUntil = response.ValidUntil;
    }
    this.tokenIssueTime = new Date();
  }
}
