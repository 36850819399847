import { CRPConnectionInfo, CrpService } from "@/crpconnect/crp.service";
import { Panel, Room } from "@/crpconnect/model/types";
import { reactive } from "vue";

export class Context {
    service: CrpService = new CrpService();
    panels: Panel[] = [];
    rooms: Room[] = [];
    message = "ABC";
    constructor() {
        this.service = new CrpService();
    }
    static getContext() {
        if (!this.instance) {
            this.instance = reactive(new Context());
        }
        return this.instance;
    }
    static instance: Context;
    async init() {
        await this.service.init();
        this.panels = await this.service.getPanels();
        this.rooms = await this.service.getRooms();
        const a = CRPConnectionInfo;
    }
}