import { FacilityItem } from './gcwrapper/api';
import { Factory } from './gcwrapper/factory';
import { Meeting, Setting, SettingResponse, Room, Media, Theme, Resources, Panel } from './model/types';
import { RoomService } from './gcwrapper/Facilities/room_service';
import { MeetingListForAdmin, Room as RoomApi, Site } from "./gcwrapper/api";
import { Constants, ReservationStatus } from './gcwrapper/Util/constants';

declare global {
  interface Window {
    chrome: any;
    webkit: any;
  }
}

export class AppError extends Error {
  constructor(message?: string) {
    if (!message) {
      super(message);
    }
    else {
      super("エラーが発生しました。");
    }
    this.name = "AppError";
  }
}

export class CrpService {
  entryPoint = CRPConnectionInfo.EndPoint;

  settingEntryPoint = CRPConnectionInfo.SettingEndPoint;
  accessKey = "";
  aaa: string = "";
  async init() {
    // Nothing today
  }
  async getRooms(): Promise<Room[]> {
    const factory = await Factory.getInstance(CRPConnectionInfo.TenantId, CRPConnectionInfo.EndPoint, CRPConnectionInfo.SettingEndPoint, CRPConnectionInfo.UserId);
    const response = await factory.room.getList();
    const rooms: Room[] = [];
    response.forEach((r) => {
      // Add rooms avalable for view to the list
      if ((r.AvailableDateFromForView.getTime() <= Date.now())
        && (r.AvailableDateToForView.getTime() < Constants.GUESTCOOL_MIN_DATE || r.AvailableDateToForView.getTime() >= Date.now())) {
        rooms.push({
          id: r.RoomId,
          name: r.RoomName,
          site: r.SiteId,
          comingMeetings: [],
          vacantLength: 0,
          maxDuration: 120,
          reservationInterval: 15,
          available: (r.AvailableDateFrom.getTime() <= Date.now())
            && (r.AvailableDateTo.getTime() < Constants.GUESTCOOL_MIN_DATE || r.AvailableDateTo.getTime() >= Date.now())
        } as Room);
      }
    });
    return rooms;
  }
  async getSites(): Promise<Site[]> {
    const factory = await Factory.getInstance(CRPConnectionInfo.TenantId, CRPConnectionInfo.EndPoint, CRPConnectionInfo.SettingEndPoint, CRPConnectionInfo.UserId);
    const response = await factory.site.getList();
    const sites: Site[] = [];
    response.forEach((r) => {
      sites.push(r);
    });
    return sites;
  }
  async getMeetings(room: number): Promise<Meeting[]> {
    const factory = await Factory.getInstance(CRPConnectionInfo.TenantId, CRPConnectionInfo.EndPoint, CRPConnectionInfo.SettingEndPoint, CRPConnectionInfo.UserId);
    const now = new Date();
    const response = await factory.reservation.getReservationList(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0), now, undefined, undefined, undefined, undefined, room);
    const meetings: Meeting[] = [];
    response.forEach((m) => {
      meetings.push({
        roomId: m.RoomId,
        id: m.MeetingId,
        startTime: m.ReservationFrom,
        endTime: m.ReservationTo,
        subject: m.MeetingName,
        owner: m.MeetingOwnerName,
        group: "",
        purpose: m.PurposeTypeName,
        checkedin: m.ReservationStatusId && m.ReservationStatusId > ReservationStatus.Entered && m.ReservationStatusId < ReservationStatus.RoomFinished,
      } as Meeting);
    })
    return meetings;
  }
  async checkin(room: number, meeting: number) {
    const factory = await Factory.getInstance(CRPConnectionInfo.TenantId, CRPConnectionInfo.EndPoint, CRPConnectionInfo.SettingEndPoint, CRPConnectionInfo.UserId);
    return await factory.reservation.checkIn(meeting);
  }
  async checkout(room: number, meeting: number) {
    const factory = await Factory.getInstance(CRPConnectionInfo.TenantId, CRPConnectionInfo.EndPoint, CRPConnectionInfo.SettingEndPoint, CRPConnectionInfo.UserId);
    return await factory.reservation.checkOut(meeting);
  }
  async extend(room: number, meeting: number, n: number) {
    const factory = await Factory.getInstance(CRPConnectionInfo.TenantId, CRPConnectionInfo.EndPoint, CRPConnectionInfo.SettingEndPoint, CRPConnectionInfo.UserId);
    return await factory.reservation.extend(meeting, n);
  }
  public getSetting = async (panel: string, lastUpdated?: Date): Promise<SettingResponse> => {
    const factory = await Factory.getInstance(CRPConnectionInfo.TenantId, CRPConnectionInfo.EndPoint, CRPConnectionInfo.SettingEndPoint, CRPConnectionInfo.UserId);
    if (lastUpdated) {
      //return await this.get(`${this.settingEntryPoint}/setting/${panel}?lastUpdated=${lastUpdated.toJSON()}`, this.accessKey).catch(()=>{currentTime: new Date()});
      return await factory.setting.getSetting(panel, lastUpdated);
    }
    else {
      return await factory.setting.getSetting(panel);
    }
  }
  public getPanel = async (panelId: string): Promise<Panel> => {
    const factory = await Factory.getInstance(CRPConnectionInfo.TenantId, CRPConnectionInfo.EndPoint, CRPConnectionInfo.SettingEndPoint, CRPConnectionInfo.UserId);
    return await factory.setting.getPanel(panelId);
  }
  public getPanels = async (): Promise<Panel[]> => {
    const factory = await Factory.getInstance(CRPConnectionInfo.TenantId, CRPConnectionInfo.EndPoint, CRPConnectionInfo.SettingEndPoint, CRPConnectionInfo.UserId);
    return await factory.setting.getPanels();
  }
  public savePanel = async (panelId: string, name: string): Promise<boolean> => {
    const factory = await Factory.getInstance(CRPConnectionInfo.TenantId, CRPConnectionInfo.EndPoint, CRPConnectionInfo.SettingEndPoint, CRPConnectionInfo.UserId);
    return await factory.setting.savePanel(panelId, name);
  }

  public getMedia = async (id: string): Promise<Media> => {
    const factory = await Factory.getInstance(CRPConnectionInfo.TenantId, CRPConnectionInfo.EndPoint, CRPConnectionInfo.SettingEndPoint, CRPConnectionInfo.UserId);
    return await factory.setting.getMedia(id);
  }
  public getTheme = async (id: string): Promise<Theme> => {
    const factory = await Factory.getInstance(CRPConnectionInfo.TenantId, CRPConnectionInfo.EndPoint, CRPConnectionInfo.SettingEndPoint, CRPConnectionInfo.UserId);
    return await factory.setting.getTheme(id);
  }
  public getResources = async (): Promise<Resources> => {
    const factory = await Factory.getInstance(CRPConnectionInfo.TenantId, CRPConnectionInfo.EndPoint, CRPConnectionInfo.SettingEndPoint, CRPConnectionInfo.UserId);
    return await factory.setting.getResources();
  }

  async useRoom(room: number, start: Date, duration: number, subject: string, purpose: string, owner: string, group: string) {
    throw new Error("API to make a reservatin is not implemented");
//    return response;
  }
}
export class CRPConnectionInfo {
  static TenantId = "";
  static UserId = ""
  static EndPoint = ""
  static SettingEndPoint = ""
  static TerminalId = "";
  static ErrorRedirect = "https://www.edgeplus.co.jp";

  static navigateToHome = () => {
    window.location.href = CRPConnectionInfo.ErrorRedirect;
  }
  static init() {
    return new Promise((resolve, reject) => {
      if (process.env.VUE_APP_CRP_GET_CONNECTION_STRING.toLowerCase() !== "true") {
        CRPConnectionInfo.TenantId = process.env.VUE_APP_CRP_TENANTID;
        CRPConnectionInfo.UserId = process.env.VUE_APP_CRP_USERID;
        CRPConnectionInfo.EndPoint = process.env.VUE_APP_CRP_ENDPOINT;
        CRPConnectionInfo.SettingEndPoint = process.env.VUE_APP_CRP_SETTINGENDPOINT;
        CRPConnectionInfo.TerminalId = process.env.VUE_APP_CRP_TERMINALID;
        resolve(true)
      }
      else {
        try {
          // iOSアプリの関数を呼び出す
          if (window.chrome) {
            window.chrome.webview.addEventListener('message', (event: MessageEvent) => {
              const response = event.data;
              const obj = JSON.parse(response);
              if (!obj.TenantId) {
                this.navigateToHome()
                resolve(false)
              }
              else {
                CRPConnectionInfo.TenantId = obj.TenantId;
                CRPConnectionInfo.UserId = obj.Alias;
                CRPConnectionInfo.SettingEndPoint = obj.SettingEndPoint;
                CRPConnectionInfo.EndPoint = obj.EndPoint;
                CRPConnectionInfo.TerminalId = obj.TerminalId;
                resolve(true);
              }
            }, { once: true });
            window.chrome.webview.postMessage("5e2f929a-2ea2-4d1d-b758-8d6b39daf8e4");
          }
          else if (window.webkit) {
            try {
              window.webkit.messageHandlers.iosPost.postMessage("5e2f929a-2ea2-4d1d-b758-8d6b39daf8e4").then((response: string) => {
                const obj = JSON.parse(response);
                if (!obj.TenantId) {
                  this.navigateToHome()
                }
                else {
                  CRPConnectionInfo.TenantId = obj.TenantId;
                  CRPConnectionInfo.UserId = obj.Alias;
                  CRPConnectionInfo.SettingEndPoint = obj.SettingEndPoint;
                  CRPConnectionInfo.EndPoint = obj.EndPoint;
                  CRPConnectionInfo.TerminalId = obj.TerminalId;
                }
              });
            }
            catch {
              this.navigateToHome()
              reject(false);
            }
            resolve(true);
          }
          else {
            this.navigateToHome()
            resolve(false);
          }
        } catch (error) {
          this.navigateToHome()
          return false;
        }  
      }
    });
  }
}
