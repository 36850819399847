/// <summary>
/// 端末のネットワーク接続がない
/// </summary>
export class NoNetworkError extends Error {
  constructor(message?: string) {
    if (!message) {
      super(message);
    }
    else {
      super("ネットワークに接続されていません。");
    }
    this.name = "NoNetworkError";
  }
}

/// <summary>
/// GuesCool Core サーバーへの接続に失敗した
/// </summary>
export class ServerOfflineError extends Error {
  constructor(message?: string) {
    if (!message) {
      super(message);
    }
    else {
      super("サーバーがオフラインです。");
    }
    this.name = "ServerOfflineError";
  }
}

/// <summary>
/// GuestCool Core サーバーからエラーが返された
/// </summary>
export class ServerError extends Error {
  constructor(message?: string) {
    if (!message) {
      super(message);
    }
    else {
      super("サーバーエラーが発生しました。");
    }
    this.name = "ServerError";
  }
}

/// <summary>
/// 会議室パネルAPIサーバーへの接続に失敗した
/// </summary>
export class PanelServerOfflineError extends Error {
  constructor(message?: string) {
    if (!message) {
      super(message);
    }
    else {
      super("設定サーバーがオフラインです。");
    }
    this.name = "UpdateServerOfflineError";
  }
}

/// <summary>
/// 会議室パネルAPIサーバーからエラーが返された
/// </summary>
export class PanelServerError extends Error {
  constructor(message?: string) {
    if (!message) {
      super(message);
    }
    else {
      super("設定サーバーエラーが発生しました。");
    }
    this.name = "UpdateServerError";
  }
}
