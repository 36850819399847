import { ServiceBase } from "../ServiceBase/service_base";
import { ReservationStatus } from "../Util/constants";
import { EndPoints, MeetingListForAdmin, Reservation } from "../api";
import { Util } from "../Util/util";
import { TokenStore } from "../factory";
import { DbResources, Media, Panel, Resources, SaveSettingRequest, SettingResponse, Theme } from "../../model/types";
import { AppError } from "@/crpconnect/crp.service";

export class SettingService extends ServiceBase {
  constructor(token: TokenStore, userId: string, tenantId: string, baseUri: string, settingBaseUri: string) {
    super(token, userId, tenantId, baseUri, settingBaseUri);
  }
  public async getPanels(): Promise<Panel[]> {
    const response: Response = await this.settingGet(
      this._token.token,
      this._tenantId,
      `${EndPoints.SETTING_PANELS}`,
      this._userId
    );


    //エラー発生時点でキャッシュは無効とする
    Util.ensureSuccess(response, "パネル一覧の取得に失敗しました");
    try {
        const text = await response.text();
      // let result: MeetingListForAdmin[] = [];
      const result: Panel[] = JSON.parse(text, Util.dateReviver);

      return result;
    }
    catch (error) {
      throw new AppError("パネル一覧の処理が失敗しました");
    }
  }

  public async getPanel(panelId: string): Promise<Panel> {
    const response: Response = await this.settingGet(
      this._token.token,
      this._tenantId,
      `${EndPoints.SETTING_PANELS}/${panelId}`,
      this._userId
    );


    //エラー発生時点でキャッシュは無効とする
    Util.ensureSuccess(response, "パネルの取得に失敗しました");
    try {
        const text = await response.text();
      // let result: MeetingListForAdmin[] = [];
      const result: Panel = JSON.parse(text, Util.dateReviver);

      return result;
    }
    catch (error) {
      throw new AppError("パネルの処理が失敗しました");
    }
  }

  public async savePanel(panelId: string, name: string): Promise<boolean> {
    const response: Response = await this.settingPost(
      this._token.token,
      this._tenantId,
      `${EndPoints.SETTING_PANELS}/${panelId}`,
      JSON.stringify({name: name}),
      this._userId
    );


    //エラー発生時点でキャッシュは無効とする
    Util.ensureSuccess(response, "パネルの保存に失敗しました");
    return true;
  }

  // 単一の予約を取得する
  public async getSetting(panel: string, lastUpdated?: Date): Promise<SettingResponse> {
    if (!panel) {
      throw new AppError("Invalid panel id");
    }

    let response: Response = new Response();
    if (lastUpdated) {
      response = await this.settingGet(
        this._token.token,
        this._tenantId,
        `${EndPoints.SETTING_SETTING}/${panel}?lastUpdated=${encodeURIComponent(lastUpdated.toJSON())}`,
        this._userId
      );
    }
    else {
      response = await this.settingGet(
        this._token.token,
        this._tenantId,
        `${EndPoints.SETTING_SETTING}/${panel}`,
        this._userId
      );
    }

    //エラー発生時点でキャッシュは無効とする
    Util.ensureSuccess(response, "設定情報の取得に失敗しました");
    try {
      const t = await response.text();
      const result: SettingResponse = JSON.parse(t, Util.dateReviver);
      return result;
    }
    catch (error) {
      throw new AppError("設定情報の処理が失敗しました");
    }
  }
  public async saveSetting(request: SaveSettingRequest) {
      request.setting.lastUpdated = new Date();
      const response = await this.settingPost(
        this._token.token,
        this._tenantId,
        `${EndPoints.SETTING_SETTINGS}`,
        JSON.stringify(request, Util.dateReplacer),
        this._userId
      );
      Util.ensureSuccess(response, "設定情報の保存に失敗しました");
      return response;
  }
  public async addMedia(media: Media): Promise<Media> {
    const response = await this.settingPost(
      this._token.token,
      this._tenantId,
      `${EndPoints.SETTING_MEDIA}`,
      JSON.stringify(media, Util.dateReplacer),
      this._userId
    );
    //エラー発生時点でキャッシュは無効とする
    Util.ensureSuccess(response, "メディアの追加が失敗しました");
    try {
      const result: Media = JSON.parse(await response.text(), Util.dateReviver);
      return result;
    }
    catch (error) {
      throw new AppError("メディアの追加が失敗しました");
    }
  }
  public async getMedia(id: string): Promise<Media> {
    const response = await this.settingGet(
      this._token.token,
      this._tenantId,
      `${EndPoints.SETTING_MEDIA}/${encodeURIComponent(id)}`,
      this._userId
    );
    //エラー発生時点でキャッシュは無効とする
    Util.ensureSuccess(response, "メディアの取得に失敗しました");
    try {
      const media: Media = JSON.parse(await response.text(), Util.dateReviver);
      return media;
    }
    catch (error) {
      throw new AppError("メディアの取得が失敗しました");
    }
  }
  public async getMedias(query: string): Promise<Media[]> {
    if (!query) {
      throw new AppError("Invalid query");
    }
    const response = await this.settingGet(
      this._token.token,
      this._tenantId,
      `${EndPoints.SETTING_MEDIA}?mime=${encodeURIComponent(query)}`,
      this._userId
    );
    //エラー発生時点でキャッシュは無効とする
    Util.ensureSuccess(response, "メディアの取得に失敗しました");
    try {
      const media: Media[] = JSON.parse(await response.text(), Util.dateReviver);
      return media;
    }
    catch (error) {
      throw new AppError("メディアの取得が失敗しました");
    }
  }
  public async getTheme(id: string): Promise<Theme> {
    const a = encodeURI(id);
    const b = encodeURIComponent(id);
    const c = `${EndPoints.SETTING_THEME}/${encodeURI(id)}`;
    const response = await this.settingGet(
      this._token.token,
      this._tenantId,
      `${EndPoints.SETTING_THEME}/${encodeURI(id)}`,
      this._userId
    );
    //エラー発生時点でキャッシュは無効とする
    Util.ensureSuccess(response, "テーマの取得に失敗しました");
    try {
      const media: Theme = JSON.parse(await response.text(), Util.dateReviver);
      return media;
    }
    catch (error) {
      throw new AppError("テーマの取得が失敗しました");
    }
  }
  public async getResources(): Promise<Resources> {
    const resources = {
      mainStatusAvailableJp: "",
      mainStatusAvailableEn: "",
      mainStatusWaitingJp: "",
      mainStatusWaitingEn: "",
      mainStatusBusyJp: "",
      mainStatusBusyEn: "",
      mainStatusExceededJp: "",
      mainStatusExceededEn: "",
      mainStatusNotInServiceJp: "",
      mainStatusNotInServiceEn: "",
      subStatusJp: "",
      subStatusEn: "",
      statusButtonReserveJp: "",
      statusButtonReserveEn: "",
      statusButtonCheckoutJp: "",
      statusButtonCheckoutEn: "",
      statusButtonCheckinJp: "",
      statusButtonCheckinEn: "",
      statusButtonExtend15Jp: "",
      statusButtonExtend15En: "",
      statusButtonExtend30Jp: "",
      statusButtonExtend30En: "",
      statusButtonDeleteJp: "",
      statusButtonDeleteEn: "",
    } as Resources;
      const response = await this.settingGet(
        this._token.token,
        this._tenantId,
        `${EndPoints.SETTING_RESOURCES}`,
        this._userId
      );
      //エラー発生時点でキャッシュは無効とする
      Util.ensureSuccess(response, "リソースの取得に失敗しました");
      try {
        const dbResources: DbResources = JSON.parse(await response.text(), Util.dateReviver);
        dbResources.forEach((r) => {
          resources[r.name as keyof Resources] = r.value;
        });
      }
      catch {
        // Ignore error
      }
      return resources;
  }
}
