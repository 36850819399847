/// <summary>
/// サイト管理クラス

import { AppError } from "@/crpconnect/crp.service";
import { EndPoints, Site } from "../api";
import { TokenStore } from "../factory";
import { ServiceBase } from "../ServiceBase/service_base"
import { Util } from "../Util/util";

/// </summary>
export class SiteService extends ServiceBase {
  /// <summary>
  /// コンストラクタ
  /// Factoryによって呼び出される
  /// </summary>
  /// <param name="token">認証Token</param>
  /// <param name="userId">操作ユーザーID</param>
  /// <param name="tenantId">TenantId</param>
  /// <param name="baseUri">end point base uri</param>
  constructor(token: TokenStore, userId: string, tenantId: string, baseUri: string, settingBaseUri: string) {
    super(token, userId, tenantId, baseUri, settingBaseUri);
  }
  /// <summary>
  /// 登録されているサイトの一覧を取得する
  /// </summary>
  /// <returns></returns>
  public async getList(): Promise<Site[]> {
      const response: Response = await this.get(
        this._token.token,
        this._tenantId,
        `${EndPoints.VERSION_PREFIX}${EndPoints.SITE}`,
        this._userId
      );
      //エラー発生時点でキャッシュは無効とする
      Util.ensureSuccess(response, "サイトの取得に失敗しました");
      try {
        const result = JSON.parse(await response.text(), Util.dateReviver);
        return result;
      }
      catch (error) {
        throw new AppError("サイトの処理が失敗しました");
      }
  }

  /// <summary>
  /// 最終更新情報の取得
  /// </summary>
  /// <returns></returns>
  public async getLastUpdate(): Promise<Date> {
      const response: Response = await this.get(
        this._token.token,
        this._tenantId,
        `${EndPoints.VERSION_PREFIX}${EndPoints.SITE}${EndPoints.LAST_UPDATE}`,
        this._userId
      );
      //エラー発生時点でキャッシュは無効とする
      Util.ensureSuccess(response, "最終更新情報の取得に失敗しました");
      try {
        const result = new Date(await response.text());
        return result;
      }
      catch (error) {
        throw new AppError("最終更新情報の処理が失敗しました");
      }
  }
}
