import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.context.panels, (panel) => {
      return (_openBlock(), _createElementBlock("div", {
        key: panel.Id
      }, _toDisplayString(panel.Name), 1))
    }), 128)),
    _createElementVNode("div", null, _toDisplayString(_ctx.context.message) + " " + _toDisplayString(_ctx.context.panels.length), 1)
  ]))
}