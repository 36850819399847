import { withAsyncContext as _withAsyncContext, defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onMounted, onUpdated, provide } from "vue";
  import { CRPConnectionInfo } from '@/crpconnect/crp.service';
  import { Context } from '@/appModel/context';
  import { useRoute } from 'vue-router';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  async setup(__props) {

let __temp: any, __restore: any

  const context: Context = Context.getContext();
  provide('context', context);
  const route = useRoute();
  ;(
  ([__temp,__restore] = _withAsyncContext(() => CRPConnectionInfo.init())),
  await __temp,
  __restore()
);
  let ep = ""
  if (route.query.ep) {
    let ep = route.query.ep;
  }
  alert(ep);
  CRPConnectionInfo.EndPoint = ep;
  ;(
  ([__temp,__restore] = _withAsyncContext(() => context.init())),
  await __temp,
  __restore()
);

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", null, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Home")
        ])),
        _: 1
      }),
      _cache[2] || (_cache[2] = _createTextVNode(" | ")),
      _createVNode(_component_router_link, { to: "/about" }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("About")
        ])),
        _: 1
      })
    ]),
    _createElementVNode("div", null, _toDisplayString(_ctx.a), 1),
    _createVNode(_component_router_view)
  ], 64))
}
}

})