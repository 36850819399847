export class GuestCoolEntityBase {
  public createdAt: Date = new Date();
  public createdBy: string = "";
  public lastUpdate: Date = new Date();
  public lastUpdateBy: string = "";

  public get lastUpdateInfo(): LastUpdateInfo {
    return { lastUpdate: this.lastUpdate, lastUpdateBy: this.lastUpdateBy } as LastUpdateInfo;
  }
}
export type LastUpdateInfo =
  {
    lastUpdate?: Date;
    lastUpdateBy: string;
  }

export enum AuthKind {
  // ローカル認証
  LocalAuth = 0,

  // Open Id Connect 連携認証
  OpenIdConnect = 1
}

export class TenantAuthInfo {
  private _tenantId: string = "";

  public get tenantId(): string {
    return this._tenantId;
  }

  public set tenantId(value: string) {
    this._tenantId = value.toUpperCase();
  }

  public authKind: AuthKind = AuthKind.LocalAuth;
  public domain: string = "";
  public hasAdditionalInfo: boolean = false;
  public additionalAttribute: string = "";
  public additionalAuthInfo: string = "";
}

export class TenantUserAuthInfo {
  public tenantId: string = "";
  public alias: string = "";
  public hash: string = "";
}

export class UserStatus {
  public statusName: string = "";
  public status: UserStatusKind = UserStatusKind.NotExists;;
  public isNewUserApprovalNeeded: NewUserApprovalType = NewUserApprovalType.ExistsOrAutomaticCreate;
  public contactPerson: string = "";
  public contactMailAddress: string = "";
}

export enum UserStatusKind {
  // 不存在
  NotExists = 0,

  // 存在
  Exists = 1,

  // 未承認
  NotApproved = 2,

  // 読み取り専用
  ReadOnly = 4,

  // 削除済み
  Deleted = 8
}

export class Tenant extends GuestCoolEntityBase {
  public name: string = "";
  public displayName: string = "";
  public displayNameYomi: string = "";

  public contactPerson: string = "";
  public contactPersonYomi: string = "";
  public contactPersonCompanyName: string = "";
  public contactPersonTitle: string = "";
  public contactPersonPhone: string = "";
  public contactPersonExtentionPhone: string = "";
  public contactPersonMobilePhone: string = "";
  public contactPersonMailAddress: string = "";
  public contactPersonZipCode: string = "";
  public contactPersonAddress: string = "";

  public iTContactPerson: string = "";
  public iTContactPersonYomi: string = "";
  public iTContactPersonCompanyName: string = "";
  public iTContactPersonTitle: string = "";
  public iTContactPersonPhone: string = "";
  public iTContactPersonExtentionPhone: string = "";
  public iTContactPersonMobilePhone: string = "";
  public iTContactPersonMailAddress: string = "";

  public iTContactPersonZipCode: string = "";
  public iTContactPersonAddress: string = "";

  public permissionContactPerson: string = "";
  public permissionContactPersonYomi: string = "";
  public permissionContactPersonCompanyName: string = "";
  public permissionContactPersonTitle: string = "";
  public permissionContactPersonPhone: string = "";
  public permissionContactPersonExtentionPhone: string = "";
  public permissionContactPersonMobilePhone: string = "";
  public permissionContactPersonMailAddress: string = "";

  public permissionContactPersonZipCode: string = "";
  public permissionContactPersonAddress: string = "";

  public facilityContactPerson: string = "";
  public facilityContactPersonYomi: string = "";
  public facilityContactPersonCompanyName: string = "";
  public facilityContactPersonTitle: string = "";
  public facilityContactPersonPhone: string = "";
  public facilityContactPersonExtentionPhone: string = "";
  public facilityContactPersonMobilePhone: string = "";
  public facilityContactPersonMailAddress: string = "";

  public facilityContactPersonZipCode: string = "";
  public facilityContactPersonAddress: string = "";

  public newUserAction: NewUserApprovalType = NewUserApprovalType.ExistsOrAutomaticCreate;
  public newUserContactPerson: string = "";
  public newUserContactPersonMailAddress: string = "";
  public companyLogo: Uint8Array = new Uint8Array();
  public servicePlan: number = 0;
  public servicePlanName: string = "";
  public maxSites: number = 0;
  public siteCount: number = 0;
  public maxRooms: number = 0;
  public roomCount: number = 0;

  public maxUsers: number = 0;
  public userCount: number = 0;

  public validFrom: Date = new Date();
  public validTo: Date = new Date();
  public isValid: boolean = false;
  public timeZone: string = "";
}

export class NewUserContactInfo {
  public canLogin: boolean = false;

  public reason: NewUserApprovalType = NewUserApprovalType.ExistsOrAutomaticCreate;
  public contactPerson: string = "";
  public contactMailAddress: string = "";
}

// 新規ユーザーがログインできない理由
export enum NewUserApprovalType {
  // 存在済み（ログイン可能）または 自動作成
  ExistsOrAutomaticCreate = 0,

  // 管理者承認が必要
  ApprovalNeeded = 1,

  // 管理者のみが作成できる
  MustBeCreatedByAdministrator = 2,
}

export class Site extends GuestCoolEntityBase {
  public SiteId: number | null = null;
  public SiteName: string = "";
  public DelimiterMinute: number = 0;
  public MaxUsageMinute: number = 0;
  public OpeningHour: string = "";
  public ClosingHour: string = "";
  public CleanUpTime: number = 0;
  public ReservationPeriodForUser: number = 0;
  public ReservationPeriodForAdmin: number = 0;
  public DisplayOrder: number = 0;
  public IsAvailable: boolean = false;
  public HasTeaService: boolean = false;
  public TeaServiceFrom: string | null = null;
  public TeaServiceTo: string | null = null;
  public HasOfficeTour: boolean = false;
  public IsVisitorMailAvailable: boolean = false;
  public AccessMapUrl: string = "";
  public FloorCount: number = 0;
  public RoomCount: number = 0;
  public MeetingSpotCount: number = 0;
}

export class Floor extends GuestCoolEntityBase {
  public floorId: number | null = null;
  public rowVersion: string = "";
  public floorName: string = "";
  public walkInAvailable: boolean = false;
  public siteId: number = 0;
  public siteName: string = "";
  public displayOrder: number = 0;
  public floorAvailable: boolean = false;
  public roomCount: number = 0;
  public meetingSpotCount: number = 0;
}

export class FacilityItem extends GuestCoolEntityBase {
  public facilityId: number | null = null;
  public facilityName: string = "";
  public displayOrder: number = 0;
  public isAvailable: boolean = false;
  public isDefaultCondition: boolean = false;
  public roomCount: number = 0;
}

export class RoomType extends GuestCoolEntityBase {
  public roomTypeId: number | null = null;
  public roomTypeName: string = "";
  public displayOrder: number = 0;
  public isAvailable: boolean = false;
  public isDefaultCondition: boolean = false;
  public roomCount: number = 0;
}

export class MeetingSpot extends GuestCoolEntityBase {
  public meetingSpotId: number = 0;
  public siteId: number = 0;
  public floorId: number = 0;
  public meetingSpotName: string = "";
  public mapImage: string = "";
  public displayOrder: number | null = null;
  public isAvailable: boolean = false;
  public usedCount: number = 0;
  public siteName: string = "";
  public floorName: string = "";
}

export class Room extends GuestCoolEntityBase {
  public RoomId: number | null = null;
  public RoomName: string = "";
  public SiteId: number = 0;
  public FloorId: number = 0;
  public RoomTypeId: number = 0;
  public Capacity: number = 0;
  public Remarks: string = "";
  public RoomIdForExchange: string = "";
  public RoomPhoneNumber: string = "";
  public DefaultMeetingSpotId: number | null = null;
  public DefaultMeetingSpotName: string = "";
  public AvailableDateFrom: Date = new Date(0);
  public AvailableDateFromForView: Date = new Date(0);
  public AvailableDateTo: Date = new Date(0);
  public AvailableDateToForView: Date = new Date(0);
  public RoomAvailable: boolean = false;
  public IsFreeArea: boolean = false;
  public RoomImage: string = "";
  public DisplayOrder: number = 0;
  public TeaServiceAvailable: boolean = false;
  public IsUsed: boolean = false;
  public SiteName: string = "";
  public FloorName: string = "";
  public RoomTypeName: string = "";
  public FacilityItems: number[] = [];
}

/// 会議室利用目的
export class PurposeType extends GuestCoolEntityBase {
  /// 利用目的ID
  public purposeTypeId: number = 0;
  /// 利用目的名
  public purposeTypeName: string = "";
}

export class LanguageResource extends GuestCoolEntityBase {
  public resourceKey: string = "";
  public language: string = ""
  public value: string = "";
}

export class FindRoomRequest {
  public siteId: number = 0;
  public floorId: number | null = null;
  public attendance: number = 0;
  public facilityItems: number[] = [];
  public roomType: number[] = [];
  public targetDate: string = "";
  public notUseRoom: boolean = false;
  public freeAreaId: number | null = null;
  public editMeetingId: number = 0;
}

export class FindFreeAreaRequest {
  public siteId: number = 0;
  public targetDate: string = "";
}

export class EndPoints {
  //バージョンプレフィックス
  public static readonly VERSION_PREFIX: string = "/v1";

  // 最終更新情報
  // Facility系の最終更新情報取得に使用する
  public static readonly LAST_UPDATE: string = "/lastupdate";

  //GET:テナント認証情報取得
  //domein をクエリパラメーターに取る
  public static readonly TENANT_AUTH_INFO: string = "/tenant/TenantInfo";

  //POST:認証要求
  //TenantUserAuthInfo を要求本文に取る
  public static readonly USERREQUEST: string = "/tenant/UserRequest";

  //POST:ユーザーの存在確認
  public static readonly ISUSEREXISTS: string = "/tenant/IsUserExists";

  //POST:ユーザーハッシュの取得
  //PUT :ユーザーハッシュの更新
  public static readonly USERHASH: string = "/tenant/UserHash";

  //GET : テナント情報取得
  //PUT : テナント情報更新
  public static readonly TENANT: string = "/tenant";

  //GET : Tenant AdditionalInfo の取得
  //PUT : Tenant AdditionalInfo の更新
  public static readonly TENANT_ADDITIONAL_INFO: string = "/tenant/AdditionalInfo";

  //GET : Tenant情報の最終更新日時を取得する
  public static readonly TENANT_LASTUPDATE: string = "/tenant/lastupdate";

  //GET : クライアント接続の要求
  public static readonly CLIENT: string = "/client";
  //POST, DELETE : クライアント接続の解除
  public static readonly CLIENT_DISCONNECT: string = "/client/disconnect";

  //GET : ロール一覧の取得
  public static readonly ROLES: string = "/administration/roles";

  //POST : ユーザーの属するRoleの取得
  public static readonly USERSROLE: string = "/administration/UsersRole";

  //GET : 全てのユーザーロールを取得する
  //PUT : 指定されたユーザーロールを更新する
  //DELETE : 指定されたユーザーロールを削除する
  public static readonly USERROLE_MAINTAIN: string = "/administration/userrolemaintain";

  //GET : 全てのユーザーを取得する
  //PUT : 指定されたユーザーを追加する
  //DELETE : 指定されたユーザーを削除する
  public static readonly USERS: string = "/administration/users";

  //PUT : ユーザーを承認済みにする
  public static readonly APPROVE_USER: string = "/administration/approveuser";

  //PUT : ユーザーを読み取り専用にする
  public static readonly SET_READONLY: string = "/administration/static readonly";

  //GET : 全ての優先会議室グループを取得する
  //PUT : 指定された優先会議室グループを更新する
  //DELETE : 指定された優先会議室グループを削除する
  public static readonly PRIORITY_GROUP: string = "/administration/prioritygroup";

  //GET : 全ての優先ユーザーを取得する
  //PUT : 指定された優先ユーザーを更新する
  //DELETE : 指定された優先ユーザーを削除する
  public static readonly PRIORITY_USER: string = "/administration/priorityuser";

  //GET : 指定されたAliasのユーザー情報を取得する (Find, not search)
  //POST : 指定されたAliasのユーザー情報を更新する
  public static readonly USER_DETAIL: string = "/administration/userdetail";

  //GET : UserDetailの検索を行う
  public static readonly SEARCH_USER: string = "/administration/searchuser";

  //GET : すべてのUserDetailの取得を行う
  public static readonly ALL_USERDETAIL: string = "/administration/alluserdetail";

  //GET : 操作ログを取得する
  //POST : バルク更新（登録・更新・削除）を行う
  public static readonly BULK_OPERATION: string = "/administration/bulk";

  //GET : 一般向け全てのお知らせの一覧
  //PUT : お知らせ情報の更新
  //DELETE : お知らせ情報の削除
  public static readonly MESSAGES: string = "/facilities/messages";

  //GET : 一般向け有効なお知らせの一覧
  public static readonly VALID_MESSAGES: string = "/facilities/messages/valid";

  //GET : サイト一覧の取得
  //PUT : サイト情報の更新
  //DELETE : サイト情報の削除
  public static readonly SITE: string = "/facilities/site";

  //GET : フロア一覧の取得
  //PUT : フロア情報の更新
  //DELETE : フロア情報の削除
  public static readonly FLOOR: string = "/facilities/floor";

  //GET : 会議室設備の取得
  //PUT : 会議室設備の更新
  //DELETE : 会議室設備の削除
  public static readonly FACILITY_ITEM: string = "/facilities/facilityitem";

  //会議室タイプ
  public static readonly ROOM_TYPE: string = "/facilities/roomtype";

  //待ち合わせ場所管理
  public static readonly MEETING_SPOT: string = "/facilities/meetingspot";

  //会議室
  public static readonly ROOM: string = "/facilities/room";

  // 会議室画像
  public static readonly ROOM_IMAGE: string = "/facilities/roomimage";
  //同じフロアにある会議室の取得
  public static readonly ROOM_NEARBY: string = "/facilities/roomnearby";
  //会議室利用目的
  public static readonly PURPOSE_TYPE: string = "/facilities/purposetype";

  //GET : 言語リソースの取得
  //PUT : 言語リソースの更新
  public static readonly RESOURCE: string = "/resources";

  //メールテンプレート
  public static readonly MAIL_TEAMPALTE: string = "/message/mailtemplate";

  //指定条件による会議室検索
  public static readonly FIND_ROOM: string = "/reservations/findroom";
  //仮予約の作成
  public static readonly CREATE_DRAFT_RESERVATION: string = "/reservations/createdraft";
  //本予約の作成・予約の更新
  public static readonly UPDATE_RESERVATION: string = "/reservations/updatereservation";
  //予約のキャンセル
  public static readonly CANCEL_RESERVATION: string = "/reservations/cancel";
  //予約一覧の取得・個別予約の取得
  public static readonly RESERVATION_LIST: string = "/reservations";
  //フリーエリアの一覧を取得する
  public static readonly FIND_FREEAREA: string = "/reservations/findfreearea";
  //管理者向け予約一覧
  //public static readonly ADMIN_MEETING_LIST: string = "/reservations/adminlist";
  public static readonly ADMIN_MEETING_LIST: string = "/meetingroomkiosk/adminlist";
  //来訪者ステータス更新
  public static readonly UPDATE_VISITOR_STATUS: string = "/reservations/updateVisitorStatus";
  //予約ステータス更新
  public static readonly UPDATE_MEETING_STATUS: string = "/reservations/updateMeetingStatus";
  //チェックアウト
  public static readonly CHECKOUT_FROM_MEETING: string = "/meetingroomkiosk/reservation/checkOut";
  //チェックイン
  public static readonly CHECKIN_TO_MEETING: string = "/meetingroomkiosk/reservation/checkIn";

  //AdmissionIdの設定
  public static readonly SET_ADMISSIONID: string = "/reservations/setAdmissionId";
  //最終更新時刻の取得
  public static readonly GET_LASTUPDATE: string = "/reservations/LastUpdate";
  //予約延長を行う
  public static readonly EXTEND_MEETING: string = "/meetingroomkiosk/reservation/extent";

  //GET    : キャンセル待ち一覧の取得
  //POST   : キャンセル待ちの作成・更新
  //DELETE : キャンセル待ちの削除
  public static readonly GET_WAITINGLIST: string = "/reservations/waitinglist";
  //月毎の予約数
  public static readonly ANALYSIS_MEETING: string = "/analysis/meeting";

  //月毎の主催者毎予約数
  public static readonly ANALYSIS_OWNER: string = "/analysis/owner";

  //月毎の登録者毎予約数
  public static readonly ANALYSIS_REGISTRANT: string = "/analysis/registrant";

  //月毎の来訪者数
  public static readonly ANALYSIS_VISITOR: string = "/analysis/visitor";

  //月毎の会議室毎予約数
  public static readonly ANALYSIS_ROOM: string = "/analysis/room";

  //設定のメディア
  public static readonly SETTING_MEDIA: string = "/medias";
  //設定のテーマ
  public static readonly SETTING_THEME: string = "/themes";
  //設定のパネル一覧
  public static readonly SETTING_PANELS: string = "/panels";
  //設定
  public static readonly SETTING_SETTING: string = "/setting";
  //設定一覧
  public static readonly SETTING_SETTINGS: string = "/settings";
  //設定のリソース
  public static readonly SETTING_RESOURCES: string = "/resources";
}
export class MeetingListItem {
  // 仮予約のデフォルト会議名ヘッダ
  public readonly DRAFT_MEETING_HEADER: string = "**DRAFT**";

  public meetingId: number = 0;
  public meetingName: string = "";
  public siteId: number = 0;
  public siteName: string = "";
  public roomName: string = "";
  public capacity: number = 0;
  public reservationFrom: Date = new Date();
  public reservationFromLocalTime: Date = this.reservationFrom;
  public isClosed: boolean = false;
  public ReservationStatusId: number = 0;

}

export class MeetingListItemDetail {
  public meetingOwnerName: string = "";
  public appointmentDateTime: string = "";
  public appointmentDateTimeLocalTime: string = "";
  public reservationTo: string = "";
  public reservationToLocalTime: string = "";
  public reservationStatusName: string = "";
  public remarks: string = "";
}

export class MeetingListForAdmin {
  public MeetingId: number | null = null;
  public MeetingName: string = "";
  public ApprointmentDatetime: Date | null = null;
  public SiteId: number | null = null;
  public SiteName: string = "";
  public FloorId: number | null = null;
  public FloorName: string = "";
  public RoomId: number | null = null;
  public RoomName: string = "";
  public Capacity: number | null = null;
  public ReservationFrom: Date | null = null;
  public ReservationTo: Date | null = null;
  public MeetingOwnerName: string = "";
  public Remarks: string = "";
  public IsClosed: boolean | null = null;
  public IsDeleted: boolean | null = null;
  public ReservationStatusId: number | null = null;
  public ReservationStatusName: string = "";
  public IsToday: boolean | null = null;
  public DayDiff: number | null = null;
  public OperationDateTimeNow: Date | null = null;
  public PurposeTypeId: number | null = null;
  public PurposeTypeName: string = "";
  public VisitorCount: number | null = null;
  public AttendeeCount: number | null = null;
  public LastUpdate: Date | null = null;
  public IsPublicMeeting: boolean | null = null;
}

export class Meeting extends GuestCoolEntityBase {
  public meetingId: number = 0;
  public meetingName: string = "";
  public siteId: number = 0;
  public purposeType: number = 0;
  public hasOfficeTour: boolean = false;
  public officeTourImplementationLocation: string = "";
  public useCafeForLunch: boolean = false;
  public useCafeForDinner: boolean = false;
  public hasHandicapVisitor: boolean = false;
  public hasUnderageVisitor: boolean = false;
  public reservationFrom: string = "";
  public reservationTo: string = "";
  public roomId: number = 0;
  public attendance: number = 0;
  public isPublicMeeting: boolean = false;
  public isDrinkServiceRequired: boolean = false;
  public costCenter: string = "";
  public meetingSpotId: number | null = null;
  public visitorListType: number = 0;
  public admissionCardMessage: string = "";
  public walkInAdmission: boolean = false;
  public proxyVisitorAdmission: boolean = false;
  public confirmationFromOwner: boolean = false;
  public firstIssuerAlias: string = "";
  public firstIssueTime: string | null = null;
  public checkInTime: string | null = null;
  public drinkServiceTime: string | null = null;
  public drinkServerAlias: string = "";
  public reservationTerminateTime: string | null = null;
  public reservationTerminatorAlias: string = "";
  public cleanUpTime: string | null = null;
  public cleanUpRegisterAlias: string = "";
  public arrivalNotificationType: number = 0;
  public visitorMailRequired: boolean = false;
  public registrantAlias: string = "";
  public registrantDepartment: string = "";
  public registrantName: string = "";
  public registrantPhoneNumber: string = "";
  public registantMailAddress: string = "";
  public meetingOwnerAlias: string = "";
  public meetingOwnerDepartment: string = "";
  public meetingOwnerName: string = "";
  public meetingOwnerPhoneNumber1: string = "";
  public meetingOwnerPhoneNumber2: string = "";
  public meetingOwnerMailAddress: string = "";
  public remarks: string = "";
  public exchangeReservationId: string = "";
  public appointmentDatetime: string | null = null;
  public registantMailRequired: boolean = false;
  public sentReserveMail: boolean = false;
  public reservationStatusId: number = 0;
  public conditionMeetingDate: string = "";
  public conditionFloor: number | null = null;
  public conditionAttendance: number | null = null;
  public conditionRoomType: string = "";
  public conditionFacilityItem: string = "";
  public hasVIP: boolean = false;
  public arrivalNotificationTarget: number = 0;
  public isDraft: boolean = false;
}

export class DraftMeeting {
  public roomId: number = 0;
  public reservationFrom: string = "";
  public reservationTo: string = "";
  public conditionMeetingDate: string = "";
  public conditionFloor: number = 0;
  public conditionAttendance: number = 0;
  public conditionRoomType: string = "";
  public conditionFacilityItem: string = "";
  public editMeetingId: number = 0;
}

export class Reservation {
  /// <summary>
  /// 予約：会議情報
  /// </summary>
  public meeting: Meeting = new Meeting();
  /// <summary>
  /// 来訪者情報
  /// </summary>
  public visitors: Visitor[] = [];
  /// <summary>
  /// 同席者
  /// </summary>
  public attendees: Attendee[] = [];

  // デフォルトコンストラクタ
//  public constructor() { }
  // 仮予約からのコンストラクタ

  public Reservation(meetingId: number, draft: DraftMeeting) {
    this.meeting.meetingId = meetingId;
    this.meeting.roomId = draft.roomId;
    this.meeting.reservationFrom = draft.reservationFrom;
    this.meeting.reservationTo = draft.reservationTo;
    this.meeting.conditionMeetingDate = draft.conditionMeetingDate;
    this.meeting.conditionFloor = draft.conditionFloor;
    this.meeting.conditionAttendance = draft.conditionAttendance;
    this.meeting.conditionRoomType = draft.conditionRoomType;
    this.meeting.conditionFacilityItem = draft.conditionRoomType;
  }

  /// <summary>
  /// 日付変更操作：日付変更操作のために作成した仮予約ID
  /// </summary>
  public editDraftMeetingId: number = 0;
  public copy(from: Reservation) {
    this.meeting.meetingName = from.meeting.meetingName;
    this.meeting.purposeType = from.meeting.purposeType;
    this.meeting.hasOfficeTour = from.meeting.hasOfficeTour;
    this.meeting.officeTourImplementationLocation = from.meeting.officeTourImplementationLocation;
    this.meeting.useCafeForLunch = from.meeting.useCafeForLunch;
    this.meeting.useCafeForDinner = from.meeting.useCafeForDinner;
    this.meeting.hasHandicapVisitor = from.meeting.hasHandicapVisitor;
    this.meeting.hasUnderageVisitor = from.meeting.hasUnderageVisitor;
    this.meeting.attendance = from.meeting.attendance;
    this.meeting.isPublicMeeting = from.meeting.isPublicMeeting;
    this.meeting.isDrinkServiceRequired = from.meeting.isDrinkServiceRequired;
    this.meeting.costCenter = from.meeting.costCenter;
    this.meeting.visitorListType = from.meeting.visitorListType;
    this.meeting.admissionCardMessage = from.meeting.admissionCardMessage;
    this.meeting.walkInAdmission = from.meeting.walkInAdmission;
    this.meeting.proxyVisitorAdmission = from.meeting.proxyVisitorAdmission;
    this.meeting.confirmationFromOwner = from.meeting.confirmationFromOwner;
    this.meeting.arrivalNotificationType = from.meeting.arrivalNotificationType;
    this.meeting.visitorMailRequired = from.meeting.visitorMailRequired;
    this.meeting.meetingOwnerAlias = from.meeting.meetingOwnerAlias;
    this.meeting.meetingOwnerDepartment = from.meeting.meetingOwnerDepartment;
    this.meeting.meetingOwnerName = from.meeting.meetingOwnerName;
    this.meeting.meetingOwnerPhoneNumber1 = from.meeting.meetingOwnerPhoneNumber1;
    this.meeting.meetingOwnerPhoneNumber2 = from.meeting.meetingOwnerPhoneNumber2;
    this.meeting.meetingOwnerMailAddress = from.meeting.meetingOwnerMailAddress;
    this.meeting.remarks = from.meeting.remarks;
    this.meeting.registantMailRequired = from.meeting.registantMailRequired;
    this.meeting.sentReserveMail = from.meeting.sentReserveMail;
    this.meeting.conditionMeetingDate = from.meeting.conditionMeetingDate;
    this.meeting.conditionFloor = from.meeting.conditionFloor;
    this.meeting.conditionAttendance = from.meeting.conditionAttendance;
    this.meeting.conditionRoomType = from.meeting.conditionRoomType;
    this.meeting.conditionFacilityItem = from.meeting.conditionFacilityItem;
    this.meeting.hasVIP = from.meeting.hasVIP;
    this.meeting.arrivalNotificationTarget = from.meeting.arrivalNotificationTarget;

    this.visitors = from.visitors.map((x, index) => {
      const nx = new Visitor();
      nx.meetingId = this.meeting.meetingId,
      nx.visitorId = index,
      nx.visitorGroupId = x.visitorGroupId,
      nx.visitorGroupName = x.visitorGroupName,
      nx.visitorType = x.visitorType,
      nx.visitorCompanyName = x.visitorCompanyName,
      nx.visitorName = x.visitorName,
      nx.visitorNameKana = x.visitorNameKana,
      nx.visitorEmail = x.visitorEmail,
      nx.primaryVisitor = x.primaryVisitor,
      nx.isDeclined = x.isDeclined

      return nx;
    });

    this.attendees = from.attendees.map(x => {
      const nx = new Attendee();
      nx.meetingId = this.meeting.meetingId,
      nx.attendeeAlias = x.attendeeAlias,
      nx.attendeeName = x.attendeeName,
      nx.attendeeMailAddress = x.attendeeMailAddress,
      nx.isNotificationRequired = x.isNotificationRequired
      return nx;
    });

  }

}

export class Visitor {
  public visitorId: number = 0;
  public meetingId: number | null = null;
  public issuanceId: string = "";
  public admissionId: string | null = null;
  public visitorGroupId: number | null = null;
  public visitorGroupName: string = "";
  public visitorType: number = 0;
  public visitorCompanyName: string = "";
  public visitorName: string = "";
  public visitorNameKana: string = "";
  public visitorEmail: string = "";
  public emailSentDatetime: string | null = null;
  public primaryVisitor: number | null = null;
  public visitorStateId: number = 0;
  public isDeclined: boolean = false;
}

export class Attendee {
  public meetingId: number = 0;
  public attendeeAlias: string = "";
  public attendeeName: string = "";
  public attendeeMailAddress: string = "";
  public isNotificationRequired: boolean = false;
}
